.sideDrawer {
  height: 100%;
  background-color: rgb(80, 80, 80);
  position: fixed;
  top: 0;
  left: 0;
  max-width: 160px;
  width: 70%;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.sideDrawerOpen {
  transform: translateX(0);
}

/* .sideDrawerClose {
  transform: translateX(-100%);
} */

.sideDrawer_mainList {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 80px 0 0 0;
  font-size: 1.4rem;
}
.sideDrawer_mainList li {
  padding: 8px;
}

.sideDrawer_simple:hover {
  background-color: #ccc;
}

.sideDrawer_simple:hover .sideDrawer_mainList-linkSimple {
  color: black;
}

.sideDrawer_mainList-linkSimple {
  color: white;
  text-decoration: none;
}

.sideDrawer_mainList-item {
  color: white;
  background-color: rgb(80, 80, 80);
  height: 250px;
}

.sideDrawer_mainList-item:hover {
  color: black;
  background-color: #ccc;
}

.sideDrawer_mainList-item:hover .sideDrawer_subList {
  display: block;
}

.sideDrawer_subList {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: none;
}

.sideDrawer_subList a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

.sideDrawer_subList li a:hover,
.sideDrawer_subList li a:active {
  color: black;
  font-weight: bold;
}

@media (min-width: 769px) {
  .sideDrawer {
    display: none;
  }
}
