.banner {
  position: fixed;
  height: 60px;
  width: 100%;
  padding: 8px 16px;
  background-color: white;
  z-index: 100;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
}

.reinaPic {
  width: 30px;
  grid-column-start: 1;
  grid-column-end: span 4;
  justify-self: start;
}

.bannerText {
  font-size: large;
  grid-column-start: 5;
  grid-column-end: span 12;
}

.frogPic {
  width: 90px;
  grid-column-start: 17;
  grid-column-end: span 4;
  justify-self: end;
}

.nav-itemsWrapper {
  position: fixed;
  left: 0px;
  top: 60px;
  width: 100%;
  padding: 8px 0px;
  background-color: white;
  z-index: 110;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(2, 1fr);
  text-align: center;
  cursor: pointer;
}

.linkText {
  display: none;
}

.nav__anarch {
  background-image: linear-gradient(to left bottom, orange, white);
  grid-column-end: span 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__criminal {
  background-image: linear-gradient(to left bottom, lightskyblue, white);
  grid-column-end: span 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__shaper {
  background-image: linear-gradient(to left bottom, greenyellow, white);
  grid-column-end: span 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__runner-neutral {
  background-image: linear-gradient(to left bottom, whitesmoke, white);
  grid-column-end: span 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__hb {
  background-image: linear-gradient(to left bottom, blueviolet, white);
  grid-column-end: span 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__jinteki {
  grid-column-end: span 4;
  background-image: linear-gradient(to left bottom, orangered, white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__nbn {
  grid-column-end: span 4;
  background-image: linear-gradient(to left bottom, yellow, white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__weyland {
  grid-column-end: span 4;
  background-image: linear-gradient(to left bottom, green, white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__corp-neutral {
  background-image: linear-gradient(to left bottom, darkgray, white);
  grid-column-end: span 4;
  display: flex;
  align-items: center;
  justify-content: center;
}


/*---STILL SMALL SCREENS---*/
@media (min-width: 500px) {
  .bannerText {
    font-size: x-large;
  }
}

/*---MEDIUM SCREENS---*/
@media (min-width: 750px) {
  .bannerText {
    font-size: xx-large;
  }
}

/*---LARGE SCREENS---*/
@media (min-width: 1000px) {
  .linkText {
    display: inherit;
  }
}

/*---VERY LARGE SCREENS---*/
@media (min-width: 1200px) {
  .linkText {
    display: inherit;
  }
}

/*---EXTREME LARGE SCREENS---*/
@media (min-width: 1500px) {
  .linkText {
    display: inherit;
  }
}

