.backgroundAnarch {
  position: fixed;
  background-image: url("../../public/images/background_anarch.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1000;
  opacity: 33%;
}

.backgroundCriminal {
  position: fixed;
  background-image: url("../../public/images/background_criminal.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1000;
  opacity: 33%;
}

.backgroundShaper {
  position: fixed;
  background-image: url("../../public/images/background_shaper.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1000;
  opacity: 33%;
}

.backgroundRunnerNeutral {
  position: fixed;
  background-image: url("../../public/images/background_runnerneutral.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1000;
  opacity: 25%;
}

.backgroundHB {
  position: fixed;
  background-image: url("../../public/images/background_hb.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1000;
  opacity: 33%;
}

.backgroundJinteki {
  position: fixed;
  background-image: url("../../public/images/background_jinteki.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1000;
  opacity: 33%;
}

.backgroundNBN {
  position: fixed;
  background-image: url("../../public/images/background_nbn.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1000;
  opacity: 33%;
}

.backgroundWeyland {
  position: fixed;
  background-image: url("../../public/images/background_weyland.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1000;
  opacity: 33%;
}

.backgroundCorpNeutral {
  position: fixed;
  background-image: url("../../public/images/background_corpneutral.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1000;
  opacity: 33%;
}

.wrapperAllCards {
  position: relative;
}

.wrapperCardType {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2px;
  row-gap: 2px;
  margin: 0px 5px 0px 5px;
}

.wrapperCardType img {
  width: 100%;
}


/*---STILL SMALL SCREENS---*/
@media (min-width: 500px) {
  .wrapperCardType {
    grid-template-columns: repeat(2, 1fr);
  }
}

/*---MEDIUM SCREENS---*/
@media (min-width: 750px) {
  .wrapperCardType {
    grid-template-columns: repeat(3, 1fr);
  }
}

/*---LARGE SCREENS---*/
@media (min-width: 1000px) {
  .wrapperCardType {
    grid-template-columns: repeat(4, 1fr);
  }
}

/*---VERY LARGE SCREENS---*/
@media (min-width: 1200px) {
  .wrapperCardType {
    grid-template-columns: repeat(5, 1fr);
    column-gap: 3px;
    row-gap: 3px;
  }
}

/*---EXTREME LARGE SCREENS---*/
@media (min-width: 1500px) {
  .wrapperCardType {
    grid-template-columns: repeat(6, 1fr);
    column-gap: 4px;
    row-gap: 4px;
  }
}

